var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("h2", { staticClass: "mt-2 mb-8 titleColor--text" }, [
        _vm._v("Fonte de dados - PIS/COFINS"),
      ]),
      _c(
        "v-row",
        { staticClass: "mt-1" },
        [
          _c(
            "v-col",
            [
              _c("upload-arquivo-zip-pis-cofins", {
                ref: "uploadDropzone",
                on: { "file-upload": _vm.update_table_status_uploads },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("table-status-uploads", { ref: "tableStatus", staticClass: "mt-5" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }