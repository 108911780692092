<template>
  <v-main>
    <h2 class="mt-2 mb-8 titleColor--text">Fonte de dados - PIS/COFINS</h2>
    <v-row class="mt-1">
      <v-col>
        <upload-arquivo-zip-pis-cofins ref="uploadDropzone" @file-upload="update_table_status_uploads" />
      </v-col>
    </v-row>
    <table-status-uploads ref="tableStatus" class="mt-5" />
  </v-main>
</template>

<script>
export default {
  name: 'PisCofinsUpload',
  components: {
    tableStatusUploads: () => import('./components/TableStatusUploadsPisCofins.vue'),
    uploadArquivoZipPisCofins: () => import('./components/UploadArquivoZipPisCofins.vue'),
  },

  data() {
    return {};
  },

  computed: {},
  methods: {
    update_table_status_uploads() {
      this.$refs.tableStatus.getAll();
    },
  },
};
</script>

<style scoped>
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
</style>
